export enum ParticipantAnonymity {
  AlwaysAnonymous = 'Always stay anonymous',
  AlwaysNamed = 'Always require name',
  DefaultAnonymous = 'Anonymous by default',
  DefaultNamed = 'Named by default',
}

export const participantAnonymityOptions = Object.values(ParticipantAnonymity).map((enumLiteral) => ({
  id: enumLiteral,
  name: enumLiteral,
}));
