import { ParticipantAnonymity } from './participant-anonymity.types';

/**
 * Map `disableAnonymous` and `defaultAnonymous` flags to dropdown values:
 *
 * | Our Dropdown value    | disable_anonymous | default_anonymous |
 * | --------------------- | ----------------- | ----------------- |
 * | Anonymous by default  | false             | true              |
 * | Named by default      | false             | false             |
 * | Always stay anonymous | true              | true              |
 * | Always require name   | true              | false             |
 *
 * @see {@link fromParticipantAnonymity} for reverse function
 */
export const toParticipantAnonymity = ({
  defaultAnonymous = false,
  disableAnonymous = false,
}: {
  defaultAnonymous?: boolean;
  disableAnonymous?: boolean;
}): ParticipantAnonymity =>
  disableAnonymous
    ? defaultAnonymous
      ? ParticipantAnonymity.AlwaysAnonymous
      : ParticipantAnonymity.AlwaysNamed
    : defaultAnonymous
      ? ParticipantAnonymity.DefaultAnonymous
      : ParticipantAnonymity.DefaultNamed;

/**
 * Map dropdown values to `disableAnonymous` and `defaultAnonymous` flags
 *
 * @see {@link toParticipantAnonymity} for more info
 */
export const fromParticipantAnonymity = (
  participantAnonymity: ParticipantAnonymity,
): { defaultAnonymous: boolean; disableAnonymous: boolean } => {
  const retValue = (disableAnonymous: boolean, defaultAnonymous: boolean) => ({
    defaultAnonymous,
    disableAnonymous,
  });

  switch (participantAnonymity) {
    case ParticipantAnonymity.DefaultAnonymous:
      return retValue(false, true);
    case ParticipantAnonymity.DefaultNamed:
      return retValue(false, false);
    case ParticipantAnonymity.AlwaysAnonymous:
      return retValue(true, true);
    default:
      return retValue(true, false);
  }
};

export const showParticipantNames = ({
  defaultAnonymous,
  disableAnonymous,
}: {
  defaultAnonymous?: boolean;
  disableAnonymous?: boolean;
}): boolean =>
  [ParticipantAnonymity.AlwaysNamed, ParticipantAnonymity.DefaultNamed].includes(
    toParticipantAnonymity({ defaultAnonymous, disableAnonymous }),
  );
